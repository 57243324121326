import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { IntegrationField } from '../components';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorAirship: Vendor = {
  logo: placeholderLogo,
  category: VendorCategory.OTHER,
  whatYoullNeedItems: ['An Airship Account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Users can configure Attentive journeys to communicate in real-time to customers with Airship Push Notifications',
    },
  ],
  companyWebsite: 'https://airship.com/',
  salesEmail: '',
  infoPagesEnabled: false,
  learnSubtext: '',
  jointValueProp: '',
  helpLinks: [],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_AIRSHIP}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'appId',
            type: IntegrationFieldType.PASSWORD,
            label: 'Application ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_AIRSHIP}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'appId',
            type: IntegrationFieldType.PASSWORD,
            label: 'Application ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'THIRD_PARTY_PROCESSOR_FORWARDER',
            label: 'Enable Push Notifications in Journeys',
            subtext: 'Send Push Notifications in Journeys with your Airship Account',
          }),
        ]}
      />
    );
  },
};
